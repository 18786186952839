import React from 'react'
import { Link, graphql, StaticQuery } from "gatsby"

const BlogRelated = () => (
    <StaticQuery
    query={graphql`
    {
        allWpBlog {
            nodes {
              title
              slug
              categories {
                nodes {
                  slug
                }
              }
            }
        }
    }
    `}
    render={data => {
    const post = data.allWpBlog.nodes;
    // console.log(post);

  return(
    post && post.slice(0, 3).map((data, index) => (
            <li  data-aos="fade-up" data-aos-delay={(index + 1) * 200} key={index}>
                <Link aria-label={data.slug} to={'/blog/'+data.slug} className="d-flex align-items-center">
                    <p className="m-0" dangerouslySetInnerHTML={{__html: data.title}} />
                </Link>
            </li>        
    ))
    )

}}></StaticQuery>
)


export default BlogRelated;