import * as React from "react"
import { graphql } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton
} from "react-share";
import BlogRelated from "../components/BlogRelated"

const BlogDetails = ({data}) => {  
    const post = data.wpBlog;

  return (
    <Layout HeadClass="header-inner">
      <Seo title={post.seo && post.seo.title} description={post.seo && post.seo.metaDesc} image={post.featuredImage && post.featuredImage.node.mediaItemUrl} focuskw={post.seo && post.seo.focuskw} dataStr={post.seoDataStructure && post.seoDataStructure.dataStructure ? post.seoDataStructure.dataStructure : '{}'} />
      
      
      <div className="media-banner overflow-hidden text-white position-relative">
        {/* <img loading="lazy" src={post.featuredImage && post.featuredImage.node.mediaItemUrl} alt={post.featuredImage && post.featuredImage.node.altText} className="w-100 d-block gatsby-image-wrapper" /> */}
        <picture>
          <source media="(min-width:1800px)" srcSet={post.featuredImage.node?.sourceUrl} />
          <source media="(min-width:1280px)" srcSet={post.featuredImage.node?.mediaDetails.sizes[4].sourceUrl} />
          <source media="(min-width:992px)" srcSet={post.featuredImage.node?.mediaDetails.sizes[1].sourceUrl} />
          <source media="(min-width:768px)" srcSet={post.featuredImage.node?.mediaDetails.sizes[3].sourceUrl} />
          <img loading="lazy" width="1920" height="1080" src={post.featuredImage.node?.mediaDetails.sizes[3].sourceUrl} alt={post.featuredImage.node?.altText} className="w-100 d-block gatsby-image-wrapper" />
      </picture>
        <div className="container position-absolute">
          <div className="row">
            <div className="col-12" data-aos="fade-up">
                <div className="mt-md-0 mt-5">
                  <div className="subtitle h5" style={{letterSpacing: 'normal'}}>News & views</div>
                  <h1 className="title position-relative mb-0" dangerouslySetInnerHTML={{__html: post.title}} />
                </div>
            </div>
          </div>
        </div>
      </div>

      <div className="pt-5 py-120">
        <div className="container">
          <div className="row mb-lg-0 mb-5 justify-content-between">
              <div className="col-12 mb-xl-5 mb-4">
                <div className="date">{post.date}</div>
              </div>
              <div className="col-xl-7 col-lg-7 mb-lg-0 mb-5 blog-single-content">
                <div dangerouslySetInnerHTML={{__html: post.content}} />
                <p></p>

                {post.tags && post.tags.nodes[0] && (
                    <div className="pt-3 pt-md-2 flex-wrap d-flex align-items-start">
                        {post.tags && post.tags.nodes.map((tag) => (
                            <span className="tag-pill" key={tag.name} dangerouslySetInnerHTML={{__html: tag.name}} />
                        ))}
                    </div>
                )}
              </div>
              <div className="col-xl-4 col-xxl-3 col-lg-4">
                {/* {post.blogCustomPost?.wordsByOfBlog?.title} */}
                {post.blogCustomPost?.wordsByOfBlog?.title ? (
                  <div className="testimonial-avatar blog-autor d-flex align-items-center" data-aos="fade-up">
                    <img loading="lazy" width="185" height="185" src={post.blogCustomPost?.wordsByOfBlog?.featuredImage.node?.mediaDetails.sizes[0]?.sourceUrl} alt={post.blogCustomPost?.wordsByOfBlog?.title} className="img-fluid gatsby-image-wrapper" />

                    <div className="ms-4 pb-2">
                        <p><small>Words by</small><br />{post.blogCustomPost?.wordsByOfBlog?.title}</p>
                        <h6 className="mb-0" dangerouslySetInnerHTML={{__html: post.author.node.user && post.author.node.user.designation}} />
                    </div>
                  </div>
                ):(
                  <div className="testimonial-avatar blog-autor d-flex align-items-center" data-aos="fade-up">
                    <img loading="lazy" width="185" height="185" src={post.author.node?.avatar.url} alt={post.author.node?.firstName + ' ' + post.author.node?.lastName} className="img-fluid gatsby-image-wrapper" />

                    <div className="ms-4 pb-2">
                        <p><small>Words by</small><br />{post.author.node?.firstName + ' ' + post.author.node?.lastName}</p>
                        <h6 className="mb-0" dangerouslySetInnerHTML={{__html: post.author.node.user && post.author.node.user.designation}} />
                    </div>
                  </div>  
                )}
                

                {/* {post.categories.nodes.length > 0 && 
                  <>
                    <h6 className="blog-sidebar-title font-semi-bold mb-4 text-uppercase" data-aos="fade-up">More News</h6>
                      <ul className="post-list mb-5 list-unstyled">
                        <BlogRelated categories={post.categories.nodes && post.categories.nodes} />
                    </ul>
                  </>
                } */}

                <h6 className="blog-sidebar-title font-semi-bold mb-4 text-uppercase" data-aos="fade-up">More News</h6>
                  <ul className="post-list mb-5 list-unstyled">
                    <BlogRelated categories={post.categories.nodes && post.categories.nodes} />
                </ul>

                <div className="py-2"></div>
                <h6 className="blog-sidebar-title font-semi-bold mb-4 text-uppercase" data-aos="fade-up">Share This Article</h6>
                <div className="hstack gap-3" data-aos="fade-up">
                  <LinkedinShareButton aria-label="Linked In" title={'Check out this Blog page on https://alkye.com/blog/' + post.slug} summary={'Check out this Supplier page on https://alkye.com/blog/' + post.slug} source={'Check out this Supplier page on https://alkye.com/blog/' + post.slug} url={`https://alkye.com/blog/${post.slug}`}>
                    {/* <StaticImage
                        src="/linked-in.webp"
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        className="img-fluid" alt="circles"
                    /> */}
                    <img src="/linked-in.webp" alt="linked-in" className="img-fluid" />
                  </LinkedinShareButton>

                  <TwitterShareButton aria-label="Twitter" url={`https://alkye.com/blog/${post.slug}`}>
                    {/* <StaticImage
                        src="/twitter-share.webp"
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        className="img-fluid" alt="circles"
                    /> */}
                    <img src="/twitter-share.webp" alt="twitter-share" className="img-fluid" />
                  </TwitterShareButton>

                  <EmailShareButton body={``} aria-label="Email" subject={'Check out this Blog page on https://alkye.com/blog/' + post.slug} url={`https://alkye.com/blog/${post.slug}`}>
                    {/* <StaticImage
                        src="/email.webp"
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        className="img-fluid" alt="circles"
                    /> */}
                    <img src="/email.webp" alt="email" className="img-fluid" />
                  </EmailShareButton>

                  <FacebookShareButton aria-label="Facebook" quote={'Check out this Blog page on https://alkye.com/blog/' + post.slug} url={`https://alkye.com/blog/${post.slug}`}>
                    {/* <StaticImage
                        src="/facebook.webp"
                        quality={100}
                        formats={["auto", "webp", "avif"]}
                        className="img-fluid" alt="circles"
                    /> */}
                    <img src="/facebook.webp" alt="facebook" className="img-fluid" />
                  </FacebookShareButton>
                </div>
              </div>
          </div>
        </div>
      </div>
    
    </Layout>
  );
}

export default BlogDetails


export const query = graphql `
  query($slug: String) {
    wpBlog(slug: { eq: $slug }) {
      seo {
        title
        metaDesc
        focuskw
      }
      seoDataStructure {
        dataStructure
      }
      title
      content
      slug
      date(formatString: "MMMM D, yyyy")
      featuredImage {
        node {
          sourceUrl
          altText
          mediaDetails {
            sizes {
              sourceUrl
            }
          }
        }
      }
      categories {
        nodes {
          slug
        }
      }
      tags {
        nodes {
          name
        }
      }
      author {
        node {
          avatar {
            url
          }
          firstName
          lastName
          user {
            designation
          }
        }
      }
      blogCustomPost {
        wordsByOfBlog {
          ... on WpTeam {
            id
            featuredImage {
              node {
                mediaDetails {
                  sizes {
                    sourceUrl
                  }
                }
              }
            }
            title
          }
        }
      }
    }
  }
`